.delivery {
  padding: 120px 0 60px;

  .page-title {
    font-size: 2rem;
    text-align: center;
  }

  .subtitle {
    margin-bottom: 60px;
  }

  .description-delivery-page {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }

  .sub-description {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }
}


@media (max-width: 760px) {
  .delivery {
    padding: 80px 20px 60px;

    .page-title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.3rem;
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .description-delivery-page {
      font-size: 1.1rem;
      padding: 0;
    }

    .sub-description {
      font-size: 1.1rem;
      padding: 0;
    }
  }
}


@media (max-width: 450px) {
  .delivery {

    .page-title {
      font-size: 1.7rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }

    .description-delivery-page {
      font-size: 1rem;
    }

    .sub-description {
      font-size: 1rem;
    }
  }
}