.language-select-field {
  margin-left: 15px;
  min-width: 55px;
}

.language-select-wrapper .language-select {
  &__control {
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      .language-select__single-value, .language-select__indicator {
        color: #67B865;
      }
    }
  }

  &__value-container, &__input-container {
    padding: 0;
    margin: 0;
  }

  &__single-value {
    color: var(--main-color);
    text-transform: uppercase;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 0;
    color: var(--main-color);
  }

  // випадаючий селект
  &__menu {
    margin-top: 5px;
    width: 64px;
    background: var(--light-bg);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
  }

  &__option {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
  }

  &__option--is-selected {
    background-color: transparent;
  }

  &__option--is-focused, &__option--is-focused:active {
    background-color: transparent;
    color: #67B865;
  }
}
