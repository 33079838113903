.about_us {
  padding: 70px 0 100px;

  .main-content {
    max-width: 1400px;
    padding: 10px 20px;
    margin: 0 auto;

    .title-wrapper {

      .page-title {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .subtitle {
        margin-bottom: 2rem;
        padding-right: 2%;

        font-weight: 400;
        text-align: right;
      }
    }

    .description{
      max-width: 1100px;
      margin: 0 auto;
    }

    .hidden-text{
      opacity: 0;
      position: fixed;
      visibility: hidden;
    }

    .advantages-list {
      padding: 2%;
      margin-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10%;

      .advantages-list-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 20px;
        border-radius: 10px;

        list-style-type: none;
        color: var(--main-color);
        background: var(--icon-bg);

        svg {
          --advantages-list-item-icon-size: 100px;

          display: block;
          width: var(--advantages-list-item-icon-size);
          height: var(--advantages-list-item-icon-size);
          margin-right: 20px;
          padding: 15px;
          border-radius: 5px;

          path {
            fill: var(--main-color);
          }
        }

        p {
          width: calc(100% - 80px);

          font-size: 1.4rem;
          line-height: 1.2;
        }
      }
    }

    .categories {

      span {
        display: block;
        margin: 4rem auto 1.2rem auto;
        width: fit-content;

        font-size: 1.3rem;
        color: var(--main-color);
        text-align: center;
      }

      p {
        text-align: center;
        margin: 0 auto;
        width: fit-content;

        font-size: 1.3rem;

        .link{
          color: var(--main-color);

          font-weight: 550;

          &:hover{
            color: #67B865;
          }
        }
      }
    }
  }
}


@media (max-width: 1300px) {
  .about_us{

    .main-content{

      .advantages-list{

        .advantages-list-item{

          p{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .about_us {

    .main-content {
      margin: 0;

      .title-wrapper{

        .subtitle{
          margin-right: 50px;
          padding-right: 0;
        }
      }

      .description{
        max-width: 800px;
      }

      .advantages-list {
        padding: 0;
        margin: 50px auto 0 auto;

        width: 90%;
        grid-template-columns: 1fr;

        .advantages-list-item {
          width: 100%;

          p{
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .about_us{
    padding: 45px 0 100px;

    .main-content{
      padding-top: 60px;

      .title-wrapper {
        display: flex;
        justify-content: space-between;

        .page-title {
          text-align: center;
        }

        .subtitle {
          font-size: 1.4rem;
          font-style: italic;
          max-width: 250px;
          margin-right: 0;
        }
      }

      .description{
        font-size: 1.5rem;
        margin-top: 15px;
        text-align: start;
      }

      .advantages-list{
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 10px;
        width: 100%;

        .advantages-list-item{
          flex-direction: column;
          padding: 40px 5px;

          svg{
            margin-right: 0;
          }

          p{
            max-width: 500px;
            font-size: 1rem;
            width: 100%;
            text-align: center;
          }
        }
      }

      .categories{

        span{
          margin: 6rem 0 1.5rem 0;
          font-size: 1.2rem;
          text-align: start;
        }

          p{
            text-align: start;
            font-size: 1.2rem;
            margin: 0;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .about_us {
    padding-bottom: 50px;

    .main-content {
      padding-top: 40px;

      .title-wrapper {

        .page-title{
          font-size: 1.8rem;
        }

        .subtitle {
          font-size: 1.2rem;
          max-width: 200px;
        }
      }

      .description{
        font-size: 1.4rem;
      }

    .advantages-list {

      .advantages-list-item {
        padding: 10px 5px;

        svg {
         width: 80px;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .categories {
      span {
        margin-top: 4rem;
        margin-bottom: 1.2rem;

        font-size: 1.1rem;
      }

      p {
        font-size: 1.1rem;
      }
    }
  }
  }
}

@media (max-width: 450px) {
  .about_us {
    padding-bottom: 30px;

    .main-content {
      padding-top: 20px;

      .title-wrapper{

        .page-title{
          font-size: 1.6rem;
        }

        .subtitle{
          font-size: 1rem;
          max-width: 170px;
        }
      }

      .description{
        font-size: 1.2rem;
      }

      .advantages-list {
        column-gap: 5px;
        row-gap: 5px;

        .advantages-list-item {
          min-width: 105px;
          //min-height: 142px;
          padding: 5px 10px;

          svg {
            width: 65px;
            height: 70px;
            padding: 10px 10px;
          }

          p {
            font-size: 0.8rem;
          }
        }
      }

      .categories {
        span {
          margin-top: 3rem;
          font-size: 1rem;
        }

        p {
         font-size: 1rem;
        }
      }
    }
  }
}


@media (max-width: 370px) {
  .about_us{

    .main-content{

      .title-wrapper{

        .page-title{
          font-size: 1.4rem;
        }

        .subtitle{
          font-size: 0.8rem;
          max-width: 140px;
        }
      }

      .description{
        font-size: 1rem;
      }

      .advantages-list{
        column-gap: 5px;
        row-gap: 5px;
        margin-top: 25px;

        .advantages-list-item{
          min-width: 80px;

          svg{
            width: 55px;
            height: 55px;
          }

          p{
            font-size: 0.6rem;
          }
        }
      }

      .categories {
        span {
          font-size: 0.9rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}