.redirect-to-faq {
  margin-left: 25px;
  width: 33px;
  height: 33px;
  color: var(--main-color);

  &.active, &:hover {
    color: #67b865;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }


  @media (max-width: 1160px) {
    margin-left: 15px;
  }

  @media (max-width: 760px) {
    margin-left: 15px;

  }

  @media (max-width: 574px) {
    width: 25px;
    height: 25px;
  }
}