.language-change-block {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .languages-block-item {
    display: flex;
  }

  .languages-block-item .languages-text {
    font-weight: 700;
    font-size: 20px;
    padding: 0 2px;
    color: var(--main-color);
    cursor: pointer;
    transition: .2s;

    &:hover {
      color: #67b865;
    }
  }

  .languages-block-item.active .languages-text {
    color: #67b865;
  }

  .line {
    width: 2px;
    height: 100%;
    background: #9E9E9E;
    margin: 0 3px 0 3px;
  }
}