.button-close {
  button[aria-label='close'] {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--main-color);

  }

  svg{
    height: 30px;
    width: 30px;
  }
}