.slider-for-portfolio {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .slide {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 40px;

    .tablet {
      width: 45%;
    }

    .computer {
      width: 45%;
    }

    .phone {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 100px;
    }

    .description {
      width: 50%;

      font-size: 1.3rem;
      text-align: center;
      margin: 0 20px;
    }
  }

  .left-button, .right-button {
    width: 60px;
    text-align: center;
    font-size: 250%;
    color: var(--main-color);
    border: none;
    cursor: pointer;
    outline: none;
    background: none;

    &:hover {
      color: #67B865;
    }
  }
}


@media (max-width: 1600px) {
  .slider-for-portfolio {
    .slide {
      .computer {
        width: 55%;
      }

    }
  }
}


@media (max-width: 1500px) {
  .slider-for-portfolio {
    .slide {
      .phone {
        width: 25%;
      }

      .tablet {
        width: 55%;
      }

    }
  }
}


@media (max-width: 1200px) {
  .slider-for-portfolio {
    .slide {
      .tablet {
        width: 65%;
      }

      .phone {
        width: 30%;
      }

      .computer {
        width: 60%;
      }

      .description {
        font-size: 1.1rem;
        margin: 0 0 0 40px;
      }
    }
  }
}


@media (max-width: 1000px) {
  .slider-for-portfolio {
    height: max-content;

    .slide {
      flex-direction: column;
      padding: 0 20px;

      .computer {
        width: 75%;
      }

      .tablet {
        width: 80%;
      }

      .phone {
        width: 37%;
        margin-left: 0;
      }

      .description {
        width: 100%;
        height: 12vh;
        margin: 40px 0 0 0;
      }
    }
  }
}


@media (max-width: 760px) {
  .slider-for-portfolio {

    .slide {

      .computer {
        width: 430px;
      }

      .tablet {
        width: 400px;
      }

      .phone {
        width: 250px;
        margin-top: 40px;
      }

      .description {
        height: 15vh;
      }
    }

    .left-button {
      &:hover {
        color: var(--main-color);
      }
    }

    .right-button {
      &:hover {
        color: var(--main-color);
      }
    }
  }
}


@media (max-width: 600px) {
  .slider-for-portfolio {
    .slide {
      .computer {
        width: 300px;
      }

      .phone {
        width: 220px;
        margin-top: 0;
      }

      .tablet {
        width: 320px;
      }

      .description {
        height: 20vh;
      }
    }
  }
}


@media (max-width: 450px) {
  .slider-for-portfolio {
    .slide {
      padding: 0 10px;

      .computer {
        width: 250px;
      }

      .tablet {
        width: 260px;
      }

      .phone {
        width: 200px;
        margin-top: 30px;
      }

      .description {
        height: 30vh;
        font-size: 1rem;
        margin-top: 40px;
      }
    }
  }
}


@media (max-width: 350px) {
  .slider-for-portfolio {
    .slide {
      .computer {
        width: 200px;
      }

      .tablet {
        width: 200px;
      }

      .phone {
        width: 180px;
      }

      .description {
        height: 43vh;
        font-size: 1rem;
        margin-top: 40px;
      }
    }
  }
}