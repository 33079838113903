.seller {
  padding: 120px 0 60px;

  .title {
    font-size: 2rem;
    text-align: center;
  }

  .subtitle {
    margin-bottom: 60px;
  }

  .description-seller-page {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }

  .sub-description {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }

  .another-link {
    text-align: center;
    margin-top: 30px;

    .link, .link:active, .link:visited {
      color: #A1C9E6;
    }

    .link:hover {
      color: #67B865;
    }
  }
}


@media (max-width: 760px) {
  .seller {
    padding: 80px 20px 60px 20px;

    .title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.3rem;
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .description-seller-page {
      font-size: 1.1rem;
      padding: 0;
    }

    .sub-description {
      font-size: 1.1rem;
      padding: 0;
    }

    .another-link {
      font-size: 1.1rem;

      .link {
        font-size: 1.1rem;
      }
    }
  }
}


@media (max-width: 450px) {
  .seller {

    .title {
      font-size: 1.7rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }

    .description-seller-page {
      font-size: 1rem;
    }

    .sub-description {
      font-size: 1rem;
    }

    .another-link {
      font-size: 1rem;
      max-width: 300px;
      margin: 30px auto 0 auto;

      .link {
        font-size: 1rem;
      }
    }
  }
}