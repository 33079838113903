.header {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 501;
}

.header__container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  width: 100%;

  .nav-list {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 6%;
  }

  .nav-list-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    height: 100%;
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color .2s linear;

    &:hover {
      color: #67B865;
    }

    &.active {
      color: #67B865;
    }

    .nav-list-submenu {
      position: absolute;
      z-index: 101;
      left: 0;
      bottom: 0;

      display: none;

      padding: 20px;
      border-radius: 10px;
      white-space: nowrap;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      transform: translate(0, 100%);

      .nav-list-item {
        display: block;
        padding: 10px;

        transition: color .2s linear;
        font-weight: 500;
        font-size: 18px;
        color: var(--main-color);
        margin-right: 0;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: #67B865;
        }
      }
    }

    &:hover {
      .nav-list-submenu {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .right-top-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;

    a.redirect-to-adminpanel-button {
      button {
        font-weight: 600;
        font-size: 1.3rem;
        padding: 12px 30px;
        color: var(--main-color);
        border: none;
        border-radius: 20px;
        background-color: var(--button-bg);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        transition: .20s;

        &:hover {
          background-color: var(--main-color);
          color: var(--whiteBleck-color);
        }
      }
    }

    .change-mode-button {
      width: 30px;
      height: 30px;
      margin-left: 25px;
      position: relative;

      .light, .night {
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.3s;
        position: absolute;
        left: 0;
        top: 0;
      }

      .hide {
        visibility: hidden;
        transform: scale(0) rotate(90deg);
      }

      .show {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 1250px) {
  .header__container {
    .nav-list {
      .nav-list-item {
        margin-right: 25px;
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 1160px) {
  .header__container {
    .nav-list {
      margin-left: 4%;

      .nav-list-item {
        margin-right: 25px;
        font-size: 1.2rem;
      }
    }

    .right-top-block {
      a.redirect-to-adminpanel-button {
        button {
          font-size: 1.1rem;
          padding: 12px 20px;
        }
      }

      .change-mode-button {
        margin-left: 15px;
      }
    }
  }
}


@media (max-width: 970px) {
  .header__container {
    .nav-list {
      .nav-list-item {
        margin-right: 15px;
        font-size: 1.1rem;
      }
    }

    .right-top-block {
      margin-right: 0;

      a.redirect-to-adminpanel-button {
        button {
          font-size: 0.9rem;
          padding: 12px 15px;
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .header {
    height: 50px;
  }

  .header__container {
    padding: 10px 0;

    .open-navigation-button {
      cursor: pointer;
      margin: 4px 0 0 15px;

      svg {
        width: 50px;
        height: 38px;
        fill: var(--main-color);
        transition: fill .2s linear;
      }

      &:hover svg {
        fill: #67B865;
      }
    }

    .right-top-block {
      margin-left: 0;

      .account-icon {
        margin: 6px 15px 0 0;
        margin-right: 0;

        a {
          width: 100%;
          height: 100%;
        }

        svg {
          fill: var(--main-color);
          max-width: 100%;
          max-height: 100%;
          transition: fill .2s linear;
        }

        &:hover svg {
          fill: #67B865;
        }
      }

      .change-mode-button {
        margin-right: 10px;
        margin-left: 15px;
      }
    }
  }
}


/*.blackout {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}*/

@media (max-width: 574px) {
  .header__container {
    .right-top-block {
      .account-icon {
        margin-top: 0;
        width: 25px;
        height: 25px;
      }

      .change-mode-button {
        width: 25px;
        height: 25px;
      }
    }
  }
}

