.social-networks {
  display: flex;
  flex-direction: column;
  align-items: center;

  .social-networks-list {
    a img {
      max-width: 40px;
      margin: 0 10px;
    }
  }
}

@media (max-width: 450px) {
  .social-networks .social-networks-list {
    display: flex;
    justify-content: space-around;

    a img {
      max-width: 35px;
      margin: 0 7px;
    }
  }
}
