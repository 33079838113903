:root {
  --main-color: #465E70;
  --blackWhite-color: #000;
  --whiteBleck-color: #fff;
  --light-bg: #fff;
  --form-elements-bg: #fff;
  --icon-bg: #D3E0E9;
  --menu-bg: #E4F4FF;
  --banner-bg: #E4EEF3;
  --button-bg: #fff;
  --gray-color: #898989;
  --disabled: #9E9E9E;
  --faq-bg: #E4EEF3;
  --faq-is0open: #A1C9E6;
  --gradient: linear-gradient(180deg, #fff 0%, #A1C9E6 100%);
  --button-gradient: linear-gradient(180deg, #D3E0E9 0%, #A1C9E6 100%);
}

body, root, .app {
  height: 100%;
}

#root {
  height: 100%
}


.app {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--light-bg);

  .container {
    width: 100%;
    flex-grow: 1;
  }

  .content-wrapper {
    height: 100%;
  }

  .content {
    padding-top: 0;
  }

  .light-bg {
    background: var(--light-bg);
  }

  .page-title {
    font-size: 2.2rem;
    color: var(--main-color);
    text-align: center;
  }

  .description {
    display: block;

    color: var(--main-color);
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
  }

  h1 {
    color: var(--main-color);
  }

  h2 {
    color: var(--main-color);
    font-size: 1.6rem;
    display: block;
    margin: 40px 0;
    text-align: center;
  }

  h3 {
    color: var(--main-color);
    font-size: 1.4rem;
    display: block;
    text-align: center;
  }

  p {
    color: var(--main-color);
    font-size: 1.2rem;
    line-height: 1.5;
    display: block;
  }

  ul {
    color: var(--main-color);
    font-size: 1.2rem;
  }

  form {
    input, textarea {
      background: var(--form-elements-bg);
    }

    textarea {
      resize: none;
      outline: none;
    }
  }

  &.light {
    .light-gradient {
      background: linear-gradient(180deg, #E4EEF3 0%, #D3E0E9 100%)
    }

    .gradient {
      background: linear-gradient(180deg, #D3E0E9 0%, #A1C9E6 100%);
    }
  }


  &.night {
    --main-color: #E1E1E1;
    --light-bg: #3d3d3d;
    --blackWhite-color: #fff;
    --whiteBleck-color: #000;
    --form-elements-bg: #E1E1E1;
    --icon-bg: #53575C;
    --menu-bg: #53575C;
    --banner-bg: #232425;
    --button-bg: #898989;
    --gray-color: #616161;
    --disabled: #8A8A8A;
    --faq-bg: #232425;
    --faq-is0open: #898989;
    --gradient: linear-gradient(180deg, #E1E1E1 0%, #000 100%);
    --button-gradient: linear-gradient(180deg, #898989 0%, #53575C 100%);

    .light-gradient {
      background: linear-gradient(180deg, #53575C 0%, #232425 100%)
    }

    .gradient {
      background: linear-gradient(180deg, #898989 0%, #53575C 100%);
    }

    form {
      button {
        &:hover {
          background: darken(#E1E1E1, 10%);
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .app {

    h2 {
      margin: 20px 0;
    }
  }
}


@media (max-width: 600px) {
  .page-title {
    font-size: 2rem;
  }

  h2, .description {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  p, ul {
    font-size: 1rem;
  }

}

@media (max-width: 420px) {
  .page-title {
    font-size: 1.8rem;
  }

  h2, .description {
    font-size: 1.2rem;
  }
}
