.admin {
  padding: 120px 0 60px;

  .page-title {
    font-size: 2rem;
    text-align: center;
  }

  .subtitle {
    margin-bottom: 60px;
  }

  .description-first {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }

  .description-second {
    max-width: 1400px;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0 40px;
  }

  .personal-office-block {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 50px;

    a.personal-office {
      button {
        width: 280px;
        height: 70px;
        border: none;
        border-radius: 20px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        background: var(--button-gradient) var(--main-color);
        transition: 0.2s;

        font-weight: 700;
        font-size: 1.5rem;
        color: var(--main-color);

        &:hover {
          background: var(--main-color);
          color: var(--whiteBleck-color);
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .admin {
    padding: 80px 20px 60px 20px;

    .page-title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.3rem;
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .description-first {
      font-size: 1.1rem;
      padding: 0;
    }

    .description-second {
      font-size: 1.1rem;
      padding: 0;
    }

    .personal-office-block {
      a.personal-office {
        button {
          font-size: 1.3rem;
          width: 240px;
          height: 65px;

          &:hover {
            background: var(--button-gradient);
            color: var(--main-color);
          }
        }
      }
    }
  }
}


@media (max-width: 450px) {
  .admin {

    .page-title {
      font-size: 1.7rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }

    .description-first {
      font-size: 1rem;
    }

    .description-second {
      font-size: 1rem;
    }

    .personal-office-block {
      a.personal-office {
        button {
          width: 220px;
          height: 60px;
          font-size: 1.2rem;
        }
      }
    }
  }
}



