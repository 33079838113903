.logo {
  display: flex;

  .logo-image {
    height: 60px;
  }
}

@media (max-width: 970px) {
  .logo .logo-image {
    height: 50px;
  }
}

@media (max-width: 760px) {
  .logo {
    margin: 0 auto;

    .logo-image {
      height: 40px;
    }
  }
}

@media (max-width: 400px) {
  .logo {
    margin-left: 5px;
    margin-right: auto;
  }
}