@keyframes showDialog {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes hideDialog {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.dialog-overlay {
  background: rgba(0, 0, 0, .65);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 199;
  animation: showDialog 0.3s linear forwards;
}