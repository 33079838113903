.footer {
  width: 100%;
  z-index: 501;

  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    padding: 10px 10px 20px;

    &.footer-container--three-col {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .title {
      color: var(--main-color);
      font-weight: 400;
      font-size: 1.1rem;
      margin: 10px 0;
      text-align: center;
    }

    .phone-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      .phone, .work_schedule {
        color: var(--main-color);

        a {
          text-decoration: none;
          color: inherit;

          &:hover {
            color: #67b865;
          }
        }
      }

      .phone {
        font-size: 1.3rem;
        font-weight: 500;
      }

      .work_schedule {
        font-weight: 500;
      }
    }

    .payment-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      .payment-list {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 55px;
          margin: 0 8px;
        }
      }
    }
  }

  .footer__application-dev {
    padding: 20px 10px;
    border-top: 1px solid var(--gray-color);
    color: var(--main-color);

    font-size: 16px;
    line-height: 19px;
    text-align: center;

    .application-dev__link {
      text-decoration: underline;
      color: inherit;
    }
  }
}


@media (max-width: 760px) {
  .footer {
    .footer-container {
      grid-template-columns: 1fr 1fr;

      &.footer-container--three-col {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .social-networks, .payment-block {
          width: 45%;
        }
      }

      .phone-block {
        margin-top: 30px;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}


@media (max-width: 450px) {
  .footer {
    .footer-container {
      padding: 20px 10px;

      .title {
        font-size: 0.9rem;
        text-align: center;
      }

      .social-networks {
        .social-networks-list {
          display: flex;
          justify-content: space-around;

          a {

            img {
              max-width: 35px;
              margin: 0 7px;
            }
          }
        }
      }

      .phone-block {
        .phone {
          font-size: 1rem;
        }

        .work_schedule {
          font-size: 0.9rem;
        }
      }

      .payment-block {
        .payment-list {
          img {
            width: 45px;
          }
        }
      }
    }
  }
}


@media (max-width: 350px) {
  .footer {
    .footer-container {
      .title {
        font-size: 0.9rem;
      }

      .phone-block {
        .phone {
          font-size: 0.8rem;
        }

        .work_schedule {
          font-size: 0.7rem;
        }
      }
    }
  }
}