.contact {
  width: 100%;
  padding: 70px 0 100px;

  .contact-block {
    width: 70%;
    min-width: 1190px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 30px 40px 50px;

    display: flex;

    .left-block {
      width: 45%;

      .left-title {
        font-size: 24px;
      }

      .social-networks {
        width: 100%;


        .main-description {
          font-size: 18px;
        }

        .section-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;

          .left-text {
            padding-right: 10px;
          }

          a {
            color: var(--main-color);
            text-decoration: underline;
            font-weight: 550;

            &:hover {
              color: #67B865;

            }
          }
        }

        .messenger {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 15px auto 0 auto;
          margin-top: 20px;

          a {
            text-decoration: none;

            p {
              color: #fff;
              text-decoration: underline;
              font-size: 22px;

              &:hover {
                color: #67b865;
              }
            }
          }

          .contact-social-icon p {
            display: flex;
            align-items: center;

            img {
              width: 40px;
              height: 40px;
            }

            span {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .contact-form {
      width: 55%;

      .right-title {
        font-size: 24px;
      }

      form {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-right: 0;

        .field-section {
          width: 100%;

          .white-bg-textarea {
            height: 15vh;
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 15px;

            textarea {
              height: 100%;
              width: 100%;
              padding: 15px;
              border-radius: 10px;
              border: none;
              outline: none;
              font-size: 15px;
            }

            .error {
              border: 1px double red;
              background-color: rgba(255, 0, 0, 0.2);
            }
          }

          .white-bg {
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 15px;

            input {
              width: 100%;
              padding: 15px;
              border-radius: 10px;
              border: none;
              outline: none;
              font-size: 15px;
            }

            .error {
              border: 1px double red;
              background-color: rgba(255, 0, 0, 0.2);
            }
          }

          .description {
            display: none;
          }

          .description-error {
            font-size: 14px;
            margin: -10px 0 5px;
            color: red;
            text-align: start;
          }
        }

        button {
          width: 180px;
          height: 45px;
          font-size: 19px;
          font-weight: 700;
          background-color: var(--button-bg);
          color: var(--main-color);
          border-radius: 15px;
          border: none;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            background-color: var(--main-color);
            color: var(--whiteBleck-color);
          }
        }

        .text-hidden {
          display: none;
        }

        .green-text {
          color: #67b865;
          font-size: 21px;
          font-weight: 500;
          text-align: center;
          margin-top: 25px;
        }

        .red-text {
          color: red;
          font-size: 21px;
          font-weight: 500;
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
}


@media (max-width: 1400px) {
  .contact {

    .contact-block {

      .left-block {
        width: 45%;

        .social-networks {

          .main-description {
            font-size: 16px;
          }

          .section-item {

            p {
              font-size: 18px;
            }
          }

          .messenger {

            a {

              p {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .contact {
    .contact-block {
      width: 90%;
      min-width: unset;

      .left-block {
        .social-networks {
          .main-description {
            font-size: 15px;
          }

          .section-item {
            margin-top: 10px;

            p {
              font-size: 17px;
            }
          }

          .messenger {
            a p {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .contact {
    .contact-block {
      .left-block {
        .left-title {
          font-size: 22px;
        }

        .social-networks {
          .main-description {
            font-size: 15px;
          }

          .section-item {
            margin-top: 10px;

            p {
              font-size: 15px;
            }
          }

          .messenger {
            margin-top: 10px;

            a p {
              font-size: 14px;
            }

            a p span {
              padding-left: 5px !important;
            }
          }
        }
      }

      .contact-form {
        .right-title {
          font-size: 22px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .contact {
    .contact-block {
      width: 95%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media (max-width: 770px) {
  .contact {
    padding: 60px 0;

    h2 {
      margin: 35px 0;
    }

    .contact-block {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 10px 30px 40px;
      border-radius: 0;

      .left-block {
        width: 100%;

        .social-networks {

          .main-description {
            font-size: 18px;
          }

          .section-item {
            margin-top: 15px;

            p {
              font-size: 20px;
            }
          }

          .messenger {
            margin-top: 15px;

            a p {
              font-size: 19px;
            }

            .contact-social-icon:nth-child(2) {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }
      }

      .contact-form {
        width: 100%;
        order: -1;
        margin-bottom: 30px;

        form {
          width: 100%;
          margin: 0 auto;

          button {

            &:hover {
              background-color: var(--button-bg);
              color: var(--main-color);
            }
          }
        }
      }
    }
  }
}


@media (max-width: 450px) {
  .contact {

    .contact-block {

      .left-block {

        .left-title {

        }

        .social-networks {

          .main-description {
            font-size: 17px;
          }

          .section-item {

            p {
              font-size: 18px;
            }
          }

          .messenger {
            flex-direction: column;

            a.contact-social-icon {
              margin-left: 0 !important;
              margin-right: 0 !important;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }

              p {
                font-size: 20px;
              }
            }
          }
        }
      }

      .contact-form {

        form {

          .green-text {
            font-size: 19px;
          }

          .red-text {
            font-size: 19px;
          }
        }
      }
    }
  }
}