.main-bg {
  width: 100%;
  height: 28vh;
  background-color: var(--banner-bg);

  display: flex;
  align-items: center;

  .title-block {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 810px;
    text-align: center;

    .strong {
      font-weight: 700;
      font-size: 50px;
      display: inline;
      color: #67B865;
    }

    .main-title {
      font-size: 50px;
      display: inline;
      color: var(--blackWhite-color);
    }
  }

  .triangle-bg {
    width: 180px;
    height: 100%;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    transform: translateX(1px);
  }

  .secondary-bg {
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .secondary-title {
      width: 100%;
      flex-grow: 1;
      padding-right: 10px;
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      color: var(--main-color);

      .cost {
        font-weight: 700;
      }

      .currency {
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 1400px) {
    .title-block {
      max-width: 710px;

      .strong {
        font-size: 45px;
      }

      .main-title {
        font-size: 45px;
      }
    }

    .secondary-bg {
      .secondary-title {
        font-size: 35px;
      }
    }
  }

  @media (max-width: 1200px) {
    .title-block {
      max-width: 550px;

      .strong {
        font-size: 35px;
      }

      .main-title {
        font-size: 35px;
      }
    }

    .secondary-bg {
      .secondary-title {
        font-size: 25px;
      }
    }
  }

  @media (max-width: 900px) {
    .title-block {
      max-width: 440px;

      .strong {
        font-size: 28px;
      }

      .main-title {
        font-size: 28px;
      }
    }

    .triangle-bg {
      width: 150px;
    }

    .secondary-bg {
      max-width: 170px;

      .secondary-title {
        font-size: 18px;
      }
    }
  }


  @media (max-width: 760px) {
    height: 17vh;

    .title-block {
      max-width: 350px;

      .strong {
        font-size: 22px;
      }

      .main-title {
        font-size: 22px;
      }
    }
  }


  @media (max-width: 600px) {

    .title-block {
      max-width: 300px;

      .strong {
        font-size: 19px;
      }

      .main-title {
        font-size: 19px;
      }
    }

    .secondary-bg {
      max-width: 140px;

      .secondary-title {
        font-size: 14px;
      }
    }
  }


  @media (max-width: 450px) {
    .title-block {
      max-width: 210px;

      .strong {
        font-size: 18px;
      }

      .main-title {
        font-size: 18px;
      }
    }

    .triangle-bg {
      width: 100px;
    }
  }


  @media (max-width: 360px) {
    .title-block {
      max-width: 200px;

      .strong {
        font-size: 17px;
      }

      .main-title {
        font-size: 17px;
      }
    }

    .triangle-bg {
      width: 70px;
    }

    .secondary-bg {
      .secondary-title {
        font-size: 13px;
      }
    }
  }
}


