.faq {
  * {
    box-sizing: border-box;
  }

  a {
    color: #A1C9E6;
    font-size: inherit;

    &:active, &:visited {
      color: #A1C9E6;
    }

    &:hover {
      color: #67b865;
    }
  }

  .faq-title-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .faq-tittle {
    font-size: 24px;
    color: var(--main-color);
    font-weight: 700;
  }

  .text-field-wrp {
    .MuiFormControl-root {
      width: 300px;
    }

    .MuiInputBase-input {
      padding: 16px;
      color: var(--main-color);
    }

    .MuiInputBase-root {
      padding-right: 0;
      border-radius: 10px;
    }

    .MuiFormLabel-root {
      transform: translate(14px, 16px) scale(1);
      color: var(--main-color);
      font-size: 18px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--main-color) !important;
    }

    .MuiInputAdornment-root .MuiButtonBase-root {
      opacity: 1;

      svg {
        color: var(--main-color);
      }
    }

    .MuiListItemText-primary {
      color: var(--main-color);
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, -10px) scale(0.75);
    }

    .Mui-disabled {
      opacity: 0.5;
    }
  }

  .MuiListItem-root {
    border-bottom: 1px solid #d9d6d6;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiCollapse-container {
    transition: none !important;
  }

  .list-faq {
    margin-left: 0;
    transition: none;

    .list-faq {
      padding-left: 10px;
    }
  }

  .list-faq .list-item {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    transition: none;

    .MuiListItemText-primary {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    & + .MuiCollapse-root .list-faq .list-item {
      font-size: 16px;
      line-height: 19px;
      padding-top: 7px;
      padding-bottom: 7px;

      &.list-item-text {
        font-size: 14px;
        border-bottom: none;
        padding-bottom: 0;

        &:last-child {
          padding-bottom: 7px;
        }
      }
    }
  }

  .list-item.open, .list-item.open + .MuiCollapse-root, .list-item.open + .MuiCollapse-entered {
    background-color: var(--faq-bg);
  }

  .list-item.open .MuiSvgIcon-root {
    color: var(--faq-is0open);
  }

  @media (max-width: 754px) {
    .faq-title-wrp {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .faq-tittle {
      margin-bottom: 20px;
    }
  }
}
