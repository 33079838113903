.portfolio {
  height: 100%;
  padding: 100px 0;
  max-width: 1400px;
  margin: 0 auto;

  .main-title{
    text-align: center;
    margin: 40px 0;
  }

  .portfolio-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .portfolio-item {
      position: relative;
      width: 550px;
      margin: 30px;
      padding: 20px;
      overflow: hidden;
      border-radius: 18px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

      display: flex;
      align-items: center;
      justify-content: center;

      .first-img{
        width: 80%;
        transition: 0.2s;
      }

      .second-img {
        width: 90%;
        transition: 0.2s;
      }

      .three-img{
        width: 90%;
        padding: 30px 0;
        transition: 0.2s;
      }

      .top-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border-radius: 18px;
        transition: 0.2s;
        opacity: 0;

        .upper-background{
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
        }

        .portfolio-item-title {
          font-weight: 600;
          font-size: 2.6rem;
          max-width: 200px;
          color: var(--main-color);
          opacity: 0;
          padding-left: 10%;
          z-index: 10;
          padding-top: 40px;
        }

        p{
          font-size: 1.7rem;
          font-weight: 600;
          opacity: 0;
          color: var(--main-color);
          padding-left: 10%;
          z-index: 10;
          padding-top: 50px;
        }
      }

      &:hover {
        .top-layout {
          opacity: 1;

          .upper-background {
            opacity: 0.8;
          }

          .portfolio-item-title{
            opacity: 1;
          }

          p{
            opacity: 1;
          }
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .portfolio{

    .portfolio-items{

      .portfolio-item{
        margin: 15px 20px;
        flex-direction: column;

        .first-img{
          width: 70%;
        }

        .second-img{
          width: 70%;
        }

        .three-img{
          width: 80%;
          padding: 20px 0 0 ;
        }

        .top-layout{
          position: static;
          align-items: center;
          opacity: 1;

            .upper-background{
              display: none;
          }


          .portfolio-item-title{
            font-weight: 600;
            font-size: 32px;
            max-width: 400px;
            color: var(--blackWhite-color);
            opacity: 1;
            padding-left: 0;
            padding-top: 20px;
            z-index: 0;
            text-align: center;
          }

          p{
            font-weight: 500;
            font-size: 21px;
            color: var(--blackWhite-color);
            opacity: 1;
            padding-left: 0;
            padding-top: 5px;
            z-index: 0;
            text-align: center;
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .portfolio{
    padding: 50px 0 30px;

    .main-title {
      font-size: 1.7rem;
      margin: 30px 0;
    }

    .portfolio-items{

      .portfolio-item{
        height: 100%;

        .top-layout{

          .portfolio-item-title{

          }

          p{
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}

.seller, .delivery {
  .minimum-characteristics{
    max-width: 1400px;
    padding: 15px 40px 0 40px;
    font-size: 16px;
    line-height: 19px;
    margin: 0 auto;

    @media (max-width: 760px){
      padding: 0;
      padding-top: 15px;
    }
  }
}

@media (max-width: 450px) {
  .portfolio{

    .main-title{
      font-size: 1.6rem;
      margin: 30px 0 20px 0;
    }

    .portfolio-items{

      .portfolio-item{
        padding: 10px;

        .three-img{
          padding-top: 10px;
        }

        .top-layout{

          .portfolio-item-title{
            font-size: 24px;
            padding-top: 10px;
          }

          p{
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (max-width: 350px) {
  .portfolio{

    .portfolio-items{

      .portfolio-item{

        .top-layout{

          .portfolio-item-title{
           font-size: 21px;
          }

          p{
            font-size: 15px;
          }
        }
      }
    }
  }
}