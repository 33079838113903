.banner-page {
  width: 100%;
  height: 34vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;

  .banner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg-banner {
      width: 2050px;
      height: 2050px;
      animation: bg-banner 8s infinite linear;
      position: absolute;
      z-index: 1;
      background: var(--gradient);
    }

    .banner-wrapper {
      width: 100%;
      border-radius: 20px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;

      .left-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 2.3rem;
          font-weight: 700;
          text-align: center;
          color: var(--blackWhite-color);
        }

        .description-title {
          font-size: 2.3rem;
          font-weight: 400;
          max-width: 500px;
          text-align: center;
          color: var(--blackWhite-color);
          margin-top: 0;
        }

        a.private-account-button {
          display: block;
          text-decoration-line: none;
          margin-top: 40px;

          button {
            font-weight: 600;
            font-size: 1.6rem;
            color: var(--main-color);
            border: none;
            border-radius: 20px;
            background-color: var(--button-bg);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.24);
            padding: 20px 30px;
            cursor: pointer;
            transition: .20s;

            &:hover {
              background-color: var(--main-color);
              color: var(--whiteBleck-color);
            }
          }
        }
      }

      .right-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 60px;

        img {
          width: 100%;
        }
      }
    }
  }
}


@keyframes bg-banner {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}


@media (max-width: 1300px) {
  .banner-page {
    .banner {
      .bg-banner {
        width: 1400px;
        height: 1400px;
      }

      .banner-wrapper {
        .left-block {
          .title {
            font-size: 1.9rem;
          }

          .description-title {
            font-size: 1.9rem;
            max-width: 400px;
            margin-bottom: 40px;
          }

          a.private-account-button {
            margin-top: 20px;

            button {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1000px) {
  .banner-page {
    .banner {
      .bg-banner {
        width: 1070px;
        height: 1070px;
      }

      .banner-wrapper {
        .left-block {
          .title {
            font-size: 1.4rem;
          }

          .description-title {
            font-size: 1.4rem;
            max-width: 300px;
            margin-bottom: 20px;
          }

          a.private-account-button {
            button {
              font-size: 1.1rem;
              padding: 15px 25px;
            }
          }
        }

        .right-block {
          margin-right: 30px;
        }
      }
    }
  }
}


@media (max-width: 760px) {
  .banner-page {
    height: 120vw;

    .banner {
      .bg-banner {
        width: 1200px;
        height: 1200px;
      }

      .banner-wrapper {
        flex-direction: column;
        margin-top: 50px;

        .left-block {
          margin-bottom: 12%;

          .title {
            font-size: 2.1rem;
          }

          .description-title {
            font-size: 2.1rem;
            max-width: 450px;
          }

          a.private-account-button {
            button {
              font-size: 1.5rem;
              padding: 15px 70px;

              &:hover {
                color: var(--main-color);
                background-color: var(--button-bg);
              }
            }
          }
        }

        .right-block {
          justify-content: center;
          margin-right: 0;

          img {
            width: 90%;
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .banner-page {
    height: 130vw;

    .banner {
      .bg-banner {
        width: 1000px;
        height: 1000px;
      }

      .banner-wrapper {
        .left-block {
          .title {
            font-size: 1.6rem;
          }

          .description-title {
            font-size: 1.6rem;
            max-width: 350px;
            margin-bottom: 10px;
          }

          a.private-account-button {
            button {
              font-size: 1.1rem;
              padding: 15px 50px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 450px) {
  .banner-page {
    height: 140vw;

    .banner {
      .bg-banner {
        width: 800px;
        height: 800px;
      }

      .banner-wrapper {
        margin-top: 70px;

        .left-block {
          margin-bottom: 16%;

          .title {
            font-size: 1.4rem;
          }

          .description-title {
            font-size: 1.4rem;
            max-width: 280px;
          }

          a.private-account-button {
            button {
              font-size: 1rem;
              padding: 15px 45px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 350px) {
  .banner-page {
    height: 150vw;

    .banner {

      .bg-banner {
        width: 650px;
        height: 650px;
      }

      .banner-wrapper {
        margin-top: 60px;

        .left-block {

          .title {
            font-size: 1.1rem;
          }

          .description-title {
            font-size: 1.1rem;
            max-width: 250px;
          }

          a {

            .private-account-button {
              padding: 15px 35px;
            }
          }
        }
      }
    }
  }
}



