.link_button {
  display: inline-block;
  width: 250px;
  min-width: 250px;
  margin: 10px 20px;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

@media (max-width: 760px) {
  .link_button {
    width: 200px;
    margin-top: 15px;
    min-width: 200px;
  }
}