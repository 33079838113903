@keyframes showMenu {
  100% {
    left: 0;
    opacity: 1;
  }
}

.nav-menu-mobile {
  position: fixed;
  left: -400px;
  top: 0;
  opacity: 0;
  min-width: 250px;
  padding: 40px 30px;
  background: var(--button-gradient);
  color: var(--main-color);
  border-radius: 10px;
  border-top-left-radius: 0;
  animation: showMenu 0.3s linear forwards;

  .nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-list-item {
    margin-bottom: 20px;
    color: var(--main-color);
    font-size: 1.2rem;
    line-height: 24px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color .2s linear;

    &:hover {
      color: #67B865;
    }

    &.active {
      color: #67B865;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .social-networks {
    margin-top: 50px;

    .title {
      margin-bottom: 10px;
    }

    .social-networks-list a img {
      max-width: 32px;
    }
  }

  .language-change-block {
    margin-top: 30px;
  }
}