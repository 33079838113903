.tariffs {
  padding: 70px 0 100px;

  .tariff-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .tariff-card {
      width: 100%;
      min-width: 220px;
      max-width: 380px;
      padding: 0 10px 20px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      margin: 30px;

      .title {
        display: block;
        padding: 5px 0;
        width: 30%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background: var(--light-bg);
        font-size: 200%;
        color: var(--main-color);
        text-align: center;
        font-weight: 700;
      }

      .price {
        width: 90%;
        margin: 10px 0;
        font-weight: 600;
        font-size: 1.3rem;
        color: var(--main-color);
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.custom {
          font-size: 1rem;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.2rem;

            strong {
              font-size: 2rem;
            }
          }
        }
      }

      .characteristics-list {
        width: 90%;

        .characteristics-item {
          list-style: none;
          position: relative;
          margin-top: 15px;
          color: var(--main-color);

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .true {
          &:before {
            background: url("../../../img/icons/done.svg") center;
            background-size: cover;
          }
        }

        .false {
          color: #9E9E9E;

          &:before {
            background: url("../../../img/icons/not-done.svg") center;
            background-size: cover;
          }
        }
      }

      a {
        margin-top: 40px;

        .button {
          background: var(--light-bg);
          width: 150px;
          height: 55px;
          border: none;
          border-radius: 10px;
          color: var(--main-color);
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
          transition: .20s;

          &:hover {
            background-color: var(--main-color);
            color: var(--whiteBleck-color);
          }
        }
      }
    }
  }

  .tariffs-select-wrp {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      margin-right: 20px;
      font-size: 20px;
      color: var(--main-color);
    }

    .tariff-select {
      &__control {
        min-width: 160px;
        min-height: 50px;
        border-color: #465E70;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: none;
      }

      &__single-value {
        font-size: 20px;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }
}


@media (max-width: 760px) {
  .tariffs {
    padding: 50px 0 40px;

    h2 {
      margin-top: 50px;
    }

    .tariff-cards {
      .tariff-card {

        .read-more-checker {
          opacity: 0;
          position: absolute;
        }

        .characteristics-list {
          max-height: 0px;
          overflow: hidden;
          transition: max-height .6s ease;
        }

        .read-more-checker:checked ~ .characteristics-list {
          max-height: 1000px;
        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 30px 0 10px;
          position: relative;


          .read-more-button {
            width: 100%;
            font-weight: 600;
            font-size: 20px;
            color: var(--main-color);

            cursor: pointer;
            text-decoration: underline;
            margin-right: 20px;

            &:hover {
              color: #67b865;
            }
          }

          a {
            margin-top: 0;

            .button {
              font-size: 20px;

              &:hover {
                background: var(--light-bg);
                color: var(--main-color);
              }

              &:focus {
                background-color: var(--main-color);
                color: var(--whiteBleck-color);
              }
            }
          }
        }
      }
    }

    .tariffs-select-wrp {
      .text {
        font-size: 18px;
      }

      .tariff-select {
        &__control {
          min-height: 40px;
        }

        &__single-value {
          font-size: 18px;
        }
      }
    }
  }
}


@media (max-width: 450px) {
  .tariffs {

    .tariff-cards {

      .tariff-card {

        .title {
          width: 20%;
        }

        .price {

          &.custom {
            font-size: 0.9rem;

            span {
              font-size: 1.1rem;

              strong {
                font-size: 1.8rem;
              }
            }
          }
        }

        .characteristics-list {

          .characteristics-item {
            font-size: 1.1rem;
          }
        }

        .buttons-wrapper {

          .read-more-button {
            font-size: 17px;
          }

          .button {
            height: 40px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .tariffs {
    .tariffs-select-wrp {
      flex-direction: column;

      .text {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .buttons-wrapper {
      flex-direction: column;
      justify-content: center;
    }

    .read-more-button {
      margin-right: 0 !important;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

@media (max-width: 350px) {
  .tariffs {

    .tariff-cards {
      .tariff-card {
        .price {

          &.custom {
            font-size: 0.8rem;

            span {
              font-size: 1rem;

              strong {
                font-size: 1.6rem;
              }
            }
          }
        }

        .characteristics-list {

          .characteristics-item {
            font-size: 1rem;
          }
        }

        .buttons-wrapper {

          .read-more-button {
            font-size: 15px;
            margin-right: 10px;
          }

          .button {
            height: 35px;
            font-size: 15px;
          }
        }
      }
    }
  }
}