body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body[style='overflow: hidden;'] {
    overflow-y: scroll!important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Scrolling */
::-webkit-scrollbar {
    width: 8px;
    background-color: #e7e7e7;
}

::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #c1c1c1;
}
